import type { SerializeFrom } from '@remix-run/node'
import type { UnpackData } from 'composable-functions'
import { z } from 'zod'

import type { getRecentActivity } from './queries/getRecentActivity.server'
import type { getShadowApps } from './queries/getShadowApps.server'
import type { getSpendByDepartment } from './queries/getSpendByDepartment.server'
import type { getUnderutilizedApps } from './queries/getUnderutilizedApps'
import type { getUpcomingDates } from './queries/getUpcomingDates.server'

export const INTENTS = z.enum(['ackReleaseDialog', 'ignoreVendor'])

export type DepartmentSpend = SerializeFrom<
  UnpackData<typeof getSpendByDepartment>
>

export type RecentActivity = SerializeFrom<UnpackData<typeof getRecentActivity>>

export type ShadowApp = SerializeFrom<UnpackData<typeof getShadowApps>>[number]

export type UpcomingDates = SerializeFrom<UnpackData<typeof getUpcomingDates>>

export type UnderutilizedApp = SerializeFrom<
  UnpackData<typeof getUnderutilizedApps>
>[number]
